import React, { useState } from 'react';

const Header = () => {
  const [active, setActive] = useState(false)
  return (
    <header className='header'>
      <a className="nav_link logo" href='#' onClick={(e) => e.preventDefault()}>
            Tyke Myson
          </a>

        <nav className={`nav ${active ? 'open' : ''}`}>
          
          <ul>
            <li className="nav_item">
              <a className="nav_link" href="https://twitter.com/TykeMyson_base">
                Twitter
              </a>
            </li>
            <li className="nav_item">
              <a className="nav_link" href="https://t.me/tm_betaversion">
                Telegram
              </a>
            </li>
            <li className="nav_item">
              <a className="nav_link" href="#">
                Chart
              </a>
            </li>
          </ul>
          <a className="nav__link-button mobile-view" href="#">
            Buy $Tyke
        </a>
        </nav>
        <a className="nav__link-button desctop-view" href="#">
          Buy $Tyke
        </a>
        <button className={`burger ${active ? 'active' : ''}`} onClick={() => {setActive(!active)}} active={active}>
          <span></span>
        </button>
      </header>
  );
};

export default Header;