import "./App.css";
import SimpleSlider from "./slider";
import lines from "./assets/lines.svg";
import linesBlack from "./assets/linesblack.svg";
import card1 from "./assets/card1.png";
import card2 from "./assets/card2.png";
import card3 from "./assets/card3.png";
import card4 from "./assets/card4.png";
import step1 from "./assets/step_1.png";
import step2 from "./assets/step_2.png";
import step3 from "./assets/step_3.png";
import step4 from "./assets/step_4.png";
import hollywood from "./assets/hollywood.png";
import horizontal from "./assets/horizontal.png";
import Header from "./Header";
import Footer from "./Footer";

function App() {
  const slides = [
    <div className="slide__content">
      <div className="img__container">
        <img src={step1} alt="Slide 1" />
      </div>
      <div className="slide__content-text">
        <p>
          Navigate to the 'Settings' option within your wallet and locate the
          networks section.Manually include a new network, and input the
          following details for Base:
          <br />
          <br />
          <b>Network Name:</b> Base RPC <br />
          <b>Endpoint:</b> https://mainnet.base.org/ <br />
          <b>Chain ID:</b> 8453 <br />
          <b>Currency Symbol:</b> ETH <br />
          <b>Block Explorer:</b> https://basescan.org
          <br />
          <br />
          Click on 'CONNECT TO BASE' after saving the information. Upon
          completion, you can connect to Base by choosing it from the network
          selection menu.
        </p>
      </div>
    </div>,
    <div className="slide__content">
      <div className="img__container">
        <img src={step2} alt="Slide 2" />
      </div>

      <div className="slide__content-text">
        <h3>Have ETH in your wallet to swap for $TYKE.</h3>
        <p>
          If you don’t have any ETH, you can buy directly on Metamask, transfer
          from another wallet, or buy on another exchange and send it to your
          wallet.
        </p>
      </div>
    </div>,
    <div className="slide__content">
      <div className="img__container">
        <img src={step3} alt="Slide 3" />
      </div>

      <div className="slide__content-text">
        <p>
          1. Change the network in your wallet to Base from the network dropdown
          menu.
          <br />
          <br />
          2. Visit https://app.uniswap.org/swap.
          <br />
          <br />
          3. Link your wallet.
          <br />
          <br />
          4. In the lower swap field, choose a token and enter the official
          $TYKE token address: TBA or click the button below
        </p>
        <a className="nav__link-button" href="#">
          Buy $Tyke
        </a>
      </div>
    </div>,
    <div className="slide__content">
      <div className="img__container">
        <img src={step4} alt="Slide 4" />
      </div>

      <div className="slide__content-text">
        <h3>
          <b>Execute the swap by following these steps:</b>
        </h3>
        <ul>
          <li>Input the desired amount of ETH.</li>
          <li>
            Adjust the slippage to 3-4% by clicking on the settings wheel.
          </li>
          <li>Click on "Swap".</li>
          <li>Confirm the transaction within your wallet.</li>
        </ul>
      </div>
    </div>,
  ];

  const controls = [
    { label: "Round 1", value: 0 },
    { label: "Round 2", value: 1 },
    { label: "Round 3", value: 2 },
    { label: "Round 4", value: 3 },
  ];

  return (
    <div className="App">
      {<Header />}
      <main>
        <section className="section__1">
          <div className="background">
            <p>Contract: TBA</p> {/*x8c81B4c816d66D36c4bF348BdeC01dBCbC70E987*/}
            {/* <div className="block_bord"><div></div></div> */}
          </div>
        </section>
        <section className="section__2">
          <div className="with-img">
            <img className="lines" src={lines} alt="lines" />
            <h1 className="section_header white">Tokenomics</h1>
          </div>
          <ul className="card-list">
            <li>
              <div className="card">
                <img src={card1} alt="card1" />
                <div className="card-text">
                  <p>Buy tax:</p>
                  <p>0%</p>
                </div>
              </div>
            </li>
            <li>
              <div className="card">
                <img src={card2} alt="card1" />
                <div className="card-text">
                  <p>Sell tax</p>
                  <p>0%</p>
                </div>
              </div>
            </li>
            <li>
              <div className="card">
                <img src={card3} alt="card1" />
                <div className="card-text">
                  <p>Supply:</p>
                  <p>1 Billion</p>
                </div>
              </div>
            </li>
            <li>
              <div className="card">
                <img src={card4} alt="card1" />
                <div className="card-text">
                  <p>Contract renounced</p>
                  <p>Liquidity Burned</p>
                </div>
              </div>
            </li>
          </ul>
          {/* <div className="block_bord"></div> */}
        </section>
        <div className="section__3_wrap">
          <section className="section__3">
            <div className="with-img">
              <h1 className="section_header black">roadmap</h1>
              <img className="lines" src={linesBlack} alt="lines" />
            </div>
            <img className="horizontal" src={horizontal} alt="horizontal" />
          </section>
        </div>

        <section className="section__4">
          <div className="with-img">
            <img className="lines" src={lines} alt="lines" />

            <h1 className="section_header white">How to buy?</h1>
          </div>
          <div className="slider__container">
            {<SimpleSlider slides={slides} controls={controls} />}
          </div>
          {/* <div className="block_bord"></div> */}
        </section>
        <div className="section__5-wrap">
          <section className="section__5">
            <h1 className="sec5head">
              Evrywun haz a blan 'till dey get pUnChEd in da mOuf. - Tyke Myson ©
            </h1>
            <div>
              <img className="hollywood" src={hollywood} alt="hollywood" />
            </div>
            {<Footer />}
          </section>
        </div>
      </main>
    </div>
  );
}

export default App;
