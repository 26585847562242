import React from 'react';

const Footer = () => {
  return (
    <footer>
        <nav className="nav_footer">
          <a className="nav_link logo" href="#">
            Tyke Myson
          </a>
          <ul>
            <li className="nav_item">
              <a className="nav_link" href="https://twitter.com/TykeMyson_base">
                Twitter
              </a>
            </li>
            <li className="nav_item">
              <a className="nav_link" href="https://t.me/tm_betaversion">
                Telegram
              </a>
            </li>
            <li className="nav_item">
              <a className="nav_link" href="#">
                Chart
              </a>
            </li>
            <li className="nav_item">
              <a className="nav_link" href="#">
                Contract: TBA
              </a>
            </li>
          </ul>
          <a className="nav__link-button" href="#">
            Buy $Tyke
          </a>
        </nav>
      </footer>
  );
};

export default Footer;